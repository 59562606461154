<template>
  <svg
    id="Ebene_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 12 8"
    style="enable-background: new 0 0 12 8"
    xml:space="preserve"
  >
    <g id="_004-down-button" transform="translate(0 -64.929)">
      <g id="Gruppe_234" transform="translate(0 64.929)">
        <rect
          v-if="enableBackground"
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="rgba(255,255,255,1)"
        />
        <path
          id="caret"
          d="M10.7,0.9L6,5.7L1.3,0.9L0.6,1.6L6,7.1l5.4-5.5L10.7,0.9z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
// TODO we need to put css code to set the color fill depending on brakepoint md, lg
export default {
  props: {
    enableBackground: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
