import Vue from 'vue'
import _ from 'lodash'
import * as d3 from 'd3'
import { bin } from 'd3-array'
import { CHART_ID_PREFIX } from './constants'
const hash = require('hash-sum')

Vue.prototype.$myInjectedFunction = (string) =>
  console.log('This is an example', string)

function autocast(d) {
  const keys = _.keys(d)

  const obj = {}
  keys.forEach((key) => {
    if (!isNaN(d[key])) {
      // we have a number
      obj[key] = +d[key]
    } else {
      obj[key] = d[key]
    }
  })
  return obj
}

Vue.prototype.$autocast = autocast

const locale = {
  decimal: '.',
  thousands: ' ',
  // This is a 'narrow space', not a regular space. Used as the thousands separator by d3.format
  grouping: [3],
  currency: ['CHF ', ''],
  dateTime: '%a. %e. %B %X %Y',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: [],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
}

const parseNumber = function parseNumber(d) {
  return d.trim() === '' ? NaN : +d
}

const timeParse = d3.timeFormatLocale(locale).parse
const dateParser = timeParse('%d.%m.%Y')
function parseDate(d) {
  return dateParser(d)
}

function extractIso(i18n) {
  const locale = _.find(i18n.locales, (l) => {
    return l.code === i18n.locale
  })
  return locale.iso
}

function translateString(x, y) {
  return 'translate(' + x + ',' + y + ')'
}

// function translate(x, y) {
//   return translateString(x, y);
// }

const categoryColors = [
  '#ebd3ae',
  '#fac873',
  '#ffa555',
  '#ff7864',
  '#644b41',
  '#658d94',
  '#8a6f85',
  '#3c76b3',
  '#964894',
  '#393b6c',
]

function calcThresholdDomain(values, domain, thresholds) {
  const mybin = bin().domain(domain) // .thresholds([200, 400, 600, 800]) // .thresholds(3)

  if (thresholds) {
    mybin.thresholds(thresholds)
  }
  const buckets = mybin(values)
  let thresholdDomain = buckets.map((d) => d.x0)
  // remove first value in order to map values to the first color
  thresholdDomain = _.drop(thresholdDomain)
  return thresholdDomain
}

// function xyCompareOld(a, b) {
//   if (a.y < b.y) {
//     return -1
//   } else if (a.y > b.y) {
//     return 1
//   } else if (a.x < b.x) {
//     return -1
//   } else if (a.x > b.x) {
//     return 1
//   } else return 0
// }

// function xyCompareOld2(a, b) {
//   if (a.x < b.x) {
//     return -1
//   } else if (a.x > b.x) {
//     return 1
//   } else if (a.y < b.y) {
//     return -1
//   } else if (a.y > b.y) {
//     return 1
//   } else return 0
// }

function xyCompare(a, b) {
  const asum = a.x + a.y
  const bsum = b.x + b.y
  return asum - bsum
}

function widthCompare(a, b) {
  return a.width < b.width ? 1 : -1
}

function dist(a, b) {
  return Math.abs(a - b)
}

function isObject(val) {
  return Object(val) === val
}

function isSelection(val) {
  return val instanceof d3.selection
}

function either(val, fallback) {
  return typeof val === 'undefined' ? fallback : val
}

function defined(val) {
  return typeof val !== 'undefined' && val != null
}

function isString(val) {
  return Object.prototype.toString.call(val) === '[object String]'
}

function measureDimensions(arg) {
  let node

  if (isString(arg)) {
    node = d3.select(arg).node()
  } else if (isSelection(arg)) {
    node = arg.node()
  } else {
    node = arg
  }

  return {
    width: node ? node.getBoundingClientRect().width : undefined,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  }
}

function propOr(key, defaultVal) {
  return function (object) {
    const value = object !== undefined ? object[key] : undefined
    return value !== undefined ? value : defaultVal
  }
}
function parseMeasurement(partialMeasurement) {
  const widthOrInf = propOr('width', Infinity)
  const screenHeightOrInf = propOr('screenHeight', Infinity)
  return {
    width: widthOrInf(partialMeasurement),
    screenHeight: screenHeightOrInf(partialMeasurement),
  }
}

function find(predicate, arr) {
  for (let i = 0; i < arr.length; i++) {
    if (predicate(arr[i])) {
      return arr[i]
    }
  }

  return undefined
}

function breakpointFind(breakpoints, partialMeasurement) {
  const measurement = parseMeasurement(partialMeasurement)
  return find(function (bp) {
    return breakpointTest(bp, measurement)
  }, breakpoints)
}

function breakpointTest(breakpoint, partialMeasurement) {
  const bpm = breakpoint.measurement
  const measurement = parseMeasurement(partialMeasurement)
  return (
    measurement.width <= bpm.width &&
    measurement.screenHeight <= bpm.screenHeight
  )
}

function aspectRatio(x, y) {
  const ar = x / y
  return function (width) {
    return width / ar
  }
}

const aspectRatio4to3 = aspectRatio(4, 3)
const aspectRatio16to10 = aspectRatio(16, 10)
const AR12TO5_MAX_HEIGHT = 500
const aspectRatio12to5 = function aspectRatio12to5(width) {
  return Math.min(aspectRatio(12, 5)(width), AR12TO5_MAX_HEIGHT)
}
aspectRatio12to5.MAX_HEIGHT = AR12TO5_MAX_HEIGHT

const defaultAspectRatios = {
  palm: aspectRatio4to3,
  // palm-sized devices
  lap: aspectRatio16to10,
  // lap-sized devices
  _: aspectRatio12to5, // all other cases, including desk
}

function parseBreakpoint(bp) {
  let measurement

  if (defined(bp.measurement)) {
    measurement = parseMeasurement(bp.measurement)
  } else {
    measurement = parseMeasurement({
      width: bp.width,
      screenHeight: bp.screenHeight,
    })
  }

  return {
    name: bp.name,
    measurement,
  }
}

function breakpointCreateSpec(spec) {
  return spec.map(parseBreakpoint).concat(
    parseBreakpoint({
      name: '_',
    })
  )
}

const breakpointDefaultSpec = (function () {
  const DEFAULT_SPEC = breakpointCreateSpec([
    {
      name: 'palm',
      width: 540,
    },
    {
      name: 'lap',
      width: 749,
    },
  ])
  return function () {
    return DEFAULT_SPEC
  }
})()

function aspectRatioAuto(measurement) {
  const bp = breakpointFind(breakpointDefaultSpec(), measurement)
  // console.log('aspectRatioAuto', measurement)
  // console.log('bp', bp)
  const ar = defaultAspectRatios[bp.name]
  // console.log('ar', ar)
  return ar(measurement.width)
}

const DEFAULT_WIDTH = 516
function calcBounds(
  arg1,
  /* bounds or selection */
  arg2
  /* [selection] */
) {
  let _bounds = null
  let selection = null

  if (arguments.length === 0) {
    _bounds = {}
  } else if (arguments.length === 1) {
    if (isObject(arg1)) {
      _bounds = arg1
    } else if (isSelection(arg1)) {
      _bounds = {}
      selection = arg1
    } else {
      _bounds = {}
      selection = d3.select(arg1)
    }
  } else {
    _bounds = arg1

    if (isSelection(arg2)) {
      selection = arg2
    } else {
      selection = d3.select(arg2)
    }
  } // All padding sides have default values

  const padding = {
    top: either(_bounds.top, 0),
    right: either(_bounds.right, 1),
    bottom: either(_bounds.bottom, 0),
    left: either(_bounds.left, 1),
  } // Width is calculated as: _bounds.width (if provided) -> selection.getBoundingClientRect().width (if provided) -> DEFAULT_WIDTH

  const dimensions = defined(selection)
    ? measureDimensions(selection)
    : {
        width: DEFAULT_WIDTH,
      }
  const width = either(_bounds.width, dimensions.width)
  const innerHeight = aspectRatioAuto(dimensions)
  const height = either(
    _bounds.height,
    innerHeight + padding.top + padding.bottom
  )
  return {
    innerHeight: height - padding.top - padding.bottom,
    innerWidth: width - padding.left - padding.right,
    padding,
    height,
    width,
    screenWidth: dimensions.screenWidth,
    screenHeight: dimensions.screenHeight,
  }
}

function identity(value) {
  return value
}

// eslint-disable-next-line no-unused-vars
function annotate(dictionary, s) {
  // strip s from <u></u>
  const strippedString = s.replace(/(<([^>]+)>)/gi, '')

  const annotation = dictionary.get(strippedString)
  // return `<span v-b-tooltip.noninteractive class="annotation" :title="${annotation}">${s}</span>`
  //   <AnnotationTooltip :tooltip-text="tooltipPlaceholder">Bilanzfehlbetrag</AnnotationTooltip
  // return ` <AnnotationTooltip :tooltip-text="${annotation}">${s}</AnnotationTooltip>`
  return `<span  class="annotation" tooltip-text="${annotation}">${strippedString}</span>`
}

// eslint-disable-next-line no-unused-vars
function annotateOld(dictionary, s) {
  const annotation = dictionary.get(s)
  // return `<span v-b-tooltip.noninteractive class="annotation" :title="${annotation}">${s}</span>`

  //   <AnnotationTooltip :tooltip-text="tooltipPlaceholder">Bilanzfehlbetrag</AnnotationTooltip

  // return ` <AnnotationTooltip :tooltip-text="${annotation}">${s}</AnnotationTooltip>`
  return `<span  class="annotation" tooltip-text="${annotation}">${s}</span>`
}

async function injectAnnotations(html, $content, iso) {
  const dictPath = `annotations/${iso}/dictionary`
  const contentDict = await $content(dictPath).fetch()
  const dictionaryData = contentDict.body

  const dictionary = new Map()
  dictionaryData.forEach((d) => {
    dictionary.set(d.Ausdruck, d.Annotation)
  })

  let annotatedHtml = html

  const keys = Array.from(dictionary.keys())
  const ukeys = keys.map((k) => {
    return `<u>${k}</u>`
  })

  const re = new RegExp(ukeys.join('|'), 'gi')

  annotatedHtml = annotatedHtml.replace(re, function (matched) {
    return annotate(dictionary, matched)
  })

  // remove all remaining underline tags, these are from accidently underlined words which are not in dictionary
  const re2 = new RegExp('<u>.*?</u>', 'gi')
  annotatedHtml = annotatedHtml.replace(re2, function (matched) {
    return matched.replace(/(<([^>]+)>)/gi, '')
  })

  return annotatedHtml
}
// eslint-disable-next-line no-unused-vars
async function injectAnnotationsOld(html, $content) {
  // console.log('injectAnnotations')
  const contentDict = await $content('annotations/dictionary').fetch()
  const dictionaryData = contentDict.body
  // console.log('dictionary', dictionary)
  const dictionary = new Map()
  dictionaryData.forEach((d) => {
    dictionary.set(d.Ausdruck, d.Annotation)
  })
  // console.log('dictionaryData', dictionaryData)

  //   <span v-b-tooltip.noninteractive class="annotation" :title="tooltipText">
  //   <slot></slot>
  // </span>
  // console.log('html', html)
  // const newHtml = _.replace(
  //   html,
  //   'Verwaltungsvermögen',
  //   annotate(dictionary, 'Verwaltungsvermögen')
  // )

  let annotatedHtml = html

  const keys = Array.from(dictionary.keys())
  // console.log('keys', keys)
  const re = new RegExp(keys.join('|'), 'gi')
  annotatedHtml = annotatedHtml.replace(re, function (matched) {
    // console.log('matched', matched)
    // return dictionary.get(matched)
    return annotate(dictionary, matched)
  })

  // console.log('annotatedHtml', annotatedHtml)

  // for (const key of dictionary.keys()) {
  //   const annotedExression = annotate(dictionary, key)
  //   console.log('key', key)
  //   console.log('annotedExression', annotedExression)
  //   // this replaces all occurences
  //   const regex = new RegExp(key)
  //   // annotatedHtml = _.replace(annotatedHtml, regex, annotedExression)

  //   annotatedHtml = annotatedHtml.replace(regex, annotedExression)
  // }

  return annotatedHtml
}

function generateUniqueId(salt) {
  // console.log('component._name', component)
  const hashValue = hash(salt) // _.uniqueId(CHART_ID_PREFIX)
  return `${CHART_ID_PREFIX}${hashValue}`
}

function compose() {
  const fns = arguments
  const start = arguments.length - 1
  return function () {
    let i = start
    let result = fns[i].apply(this, arguments)

    while (i--) {
      result = fns[i].call(this, result)
    }

    return result
  }
}

export {
  parseDate,
  parseNumber,
  extractIso,
  translateString,
  categoryColors,
  calcThresholdDomain,
  xyCompare,
  widthCompare,
  dist,
  calcBounds,
  identity,
  injectAnnotations,
  generateUniqueId,
  compose,
  autocast,
}

// Vue.prototype.$autocast = autocast

// Vue.prototype.$parseDate = parseDate
// Vue.prototype.$parseNumber = parseNumber
