var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"container-xl px-3 px-md-4 px-lg-5"},[_c('nuxt-link',{staticClass:"pre-fetch-link",attrs:{"to":_vm.localePath('/kennzahlen/gemeinden')}},[_vm._v(".")]),_vm._v(" "),_c('b-navbar',{staticClass:"px-0 py-be-1 py-lg-0 m-0",attrs:{"toggleable":"lg","type":"light"}},[_c('b-navbar-brand',{staticClass:"mt-3 mt-lg-4 mb-3 mb-lg-4"},[_c('NuxtLink',{attrs:{"to":_vm.localePath('index')}},[_c('img',{staticClass:"logo",attrs:{"src":require("assets/img/Kanton-Bern.svg"),"alt":"Logo Kanton Bern"}})]),_vm._v(" "),_c('div',{staticClass:"logo-caption"},[_vm._v(_vm._s(_vm.$t('title')))])],1),_vm._v(" "),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_vm._v(" "),_c('b-collapse',{staticClass:"flex-column",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto main-menu py-3 py-lg-0 mt-lg-be-3 mb-lg-be-5 order-2"},[_c('b-nav-item',{attrs:{"to":_vm.localePath('index'),"exact":"","exact-active-class":"active"}},[_vm._v(_vm._s(_vm.$t('home')))]),_vm._v(" "),_c('NavItemDropdownArrow',{attrs:{"label":_vm.$t('finance'),"paths":[_vm.localePath('bilanz'), _vm.localePath('erfolgsrechnung')]}},[_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('bilanz')}},[_vm._v(_vm._s(_vm.$t('balance')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('erfolgsrechnung')}},[_vm._v(_vm._s(_vm.$t('income_statement')))])],1),_vm._v(" "),_c('NavItemDropdownArrow',{attrs:{"label":_vm.$t('key_figures'),"paths":[
              _vm.localePath('/kennzahlen/gemeinden'),
              _vm.localePath('/kennzahlen/verwaltungskreise'),
              _vm.localePath('/kennzahlen/kantone'),
            ]}},[_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('/kennzahlen/gemeinden')}},[_vm._v(_vm._s(_vm.$t('communities')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('/kennzahlen/verwaltungskreise')}},[_vm._v(_vm._s(_vm.$t('adminstrational_districts')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('/kennzahlen/kantone')}},[_vm._v(_vm._s(_vm.$t('cantons')))])],1),_vm._v(" "),_c('NavItemDropdownArrow',{attrs:{"label":_vm.$t('comparison'),"paths":[
              _vm.localePath('/gemeindevergleich/auswahl'),
              _vm.localePath('/gemeindevergleich/alle')
            ]}},[_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('/gemeindevergleich/auswahl')}},[_vm._v(_vm._s(_vm.$t('gemeindevergleich_individuell')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"exact":"","exact-active-class":"active","to":_vm.localePath('/gemeindevergleich/alle')}},[_vm._v(_vm._s(_vm.$t('gemeindevergleich_explorer')))])],1)],1),_vm._v(" "),_c('b-navbar-nav',{staticClass:"ml-auto service-nav pt-3 pb-3a py-lg-0 mt-lg-be-2 mb-lg-5 order-1"},[_c('b-nav-item',{staticClass:"service-menu",attrs:{"href":_vm.agrContactLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('contact')))]),_vm._v(" "),_c('div',{staticClass:"language-selector"},[_c('b-nav-item',{staticClass:"language",attrs:{"to":_vm.switchLocalePath('de'),"exact":"","exact-active-class":"active"}},[_vm._v("DE")]),_vm._v(" "),_c('span',[_vm._v("|")]),_vm._v(" "),_c('b-nav-item',{staticClass:"language",attrs:{"to":_vm.switchLocalePath('fr'),"exact":"","exact-active-class":"active"}},[_vm._v("FR")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }