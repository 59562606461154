<template>
  <div class="container-xl main-container pb-3">
    <h2 v-if="error.statusCode === 404">
      {{ $t('error_page_not_found_header') }}
    </h2>
    <p v-if="error.statusCode === 404">
      {{ $t('error_page_not_found_body') }}
    </p>
    <h2 v-if="error.statusCode !== 404">{{ $t('error_occured') }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  // change this back to default when going live
  layout: 'error', // you can set a custom layout for the error page
}
</script>
