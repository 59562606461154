<template>
  <button
    title=""
    :style="{
      visibility: buttonVisibilityProperty,
      opacity: buttonOpacity,
    }"
    class="jump-2-top p-0 position-fixed"
    @click="topFunction"
  ></button>
</template>

<script>
export default {
  data() {
    return {
      buttonVisibilityProperty: 'hideen',
      buttonOpacity: 0,
      lastScrollTop: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      const st = document.documentElement.scrollTop
      if (st > this.lastScrollTop) {
        if (st > 400) {
          this.buttonDisplayProperty = 'block'
          this.buttonVisibilityProperty = 'visible'
        }
        if (st > 500) {
          this.buttonOpacity = 0.6
        }
      } else {
        if (st < 500) {
          this.buttonOpacity = 0
        }
        if (st < 400) {
          this.buttonDisplayProperty = 'none'
          this.buttonVisibilityProperty = 'hidden'
        }
      }
      this.lastScrollTop = st
    },
    topFunction() {
      if (process.client) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>

<style></style>
