/**
 * This is used for synchronising the selected municipalities between the different views in Gemeindevergleich
 */

import _ from 'lodash'

export const state = () => ({
  list: [],
  selection: [],
})

export const mutations = {
  add(state, municipality) {
    console.log('add', municipality)
    const containsMunicipality = _.some(state.selection, {
      geoId: municipality.geoId,
    })
    // only add municiplaity if its not already contained
    if (!containsMunicipality) {
      state.selection.push(municipality)
    }
  },
  set(state, arr) {
    console.log('set municipalities in store')
    state.list = arr

    // populate selection
    // chose a random municipality
    const sample = _.sample(arr)
    const typology = sample.typology

    // get all municpialites with that topology
    // const selection = arr.filter((d) => {
    //   return d.geoId === 535 || d.typology === typology // always add Deisswil bei Münchenbuchsee,  535
    // })

    const selection = arr.filter((d) => {
      return d.typology === typology // always add Deisswil bei Münchenbuchsee,  535
    })

    // const deisswil = _.find(selection, { geoId: 535 })

    state.selection = _.slice(selection, 0, 5)
    // state.selection.push(deisswil)
  },
  setSelection(state, arr) {
    // get the selection from state.list, in order not to mutate state.list
    const selection = state.list.filter((d) => {
      return _.some(arr, { geoId: d.geoId })
    })

    const sortedGeoIds = arr.map((d) => {
      return d.geoId
    })
    // sort the new selection according to the passed array
    selection.sort(function (a, b) {
      return _.indexOf(sortedGeoIds, a.geoId) - _.indexOf(sortedGeoIds, b.geoId)
    })

    state.selection = selection
    // _.some(users, { 'user': 'barney', 'active': false });
  },
  remove(state, municipality) {
    state.selection = _.reject(state.selection, (d) => {
      return d.geoId === municipality.geoId
    })
  },
}

export const getters = {
  get(state) {
    return state.list
  },
  getSelection(state) {
    return state.selection
  },
}
