<template>
  <div>
    <HeaderComponent />
    <main role="main">
      <nuxt />
      <Jump2Top />
      <div class="clearfix invisible">_</div>
    </main>
    <FooterComponent />
  </div>
</template>
<script>
import HeaderComponent from '@/components/layout/HeaderComponent'
import FooterComponent from '@/components/layout/FooterComponent'
import Jump2Top from '@/components/layout/Jump2Top'
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Jump2Top,
  },
  data() {
    return {
      baseUrl: 'https://www.ebericht.be.ch/',
      timestamp: '-',
    }
  },
  fetch() {
    // setting a timestamp at build time to show in footer
    if (process.server) {
      // get the current date as ISO string
      this.timestamp = new Date().toISOString()
    }
  },
  computed: {
    defaultCardUrl() {
      return `${this.baseUrl}${this.defaultCardFile}`
    },
    twitterCardUrl() {
      return `${this.baseUrl}${this.twitterCardFile}`
    },
    twitterCardFile() {
      return this.defaultCardFile
    },
    defaultCardFile() {
      // return 'agr-ebericht-card-800x418.png'
      return 'agr-ebericht-card.png'
      // return 'gross.png'
    },
  },
  head() {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    const seo = {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      title: this.$i18n.t('meta_title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('meta_description'),
        },
        // Twitter
        // Test on: https://cards-dev.twitter.com/validator
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        { hid: 'twitter:site', name: 'twitter:site', content: '@kanton_bern' },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: 'https://www.be.ch/gemfin',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.$i18n.t('twitter_title'),
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.$i18n.t('twitter_description'),
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: this.twitterCardUrl,
        },

        // Open Graph
        // Test on: https://developers.facebook.com/tools/debug/
        { hid: 'og:site_name', property: 'og:site_name', content: 'Nuxt' },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.baseUrl,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$i18n.t('twitter_title'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$i18n.t('twitter_description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.defaultCardUrl,
        },
        {
          hid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: this.defaultCardUrl,
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: 'Bericht Gemeindefinanzen',
        },
        ...i18nSeo.meta,
        // additional meta tags added 26.5.21 -> see https://github.com/ebericht-review/design-review-rc1/issues/186
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$i18n.t('meta_keywords'),
        },
        {
          hid: 'timestamp',
          name: 'timestamp',
          content: this.timestamp,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ebericht.be.ch' + this.$route.path,
        },
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
        {
          rel: 'icon',
          type: 'image/x-icon',
          sizes: '16x16',
          href: '/favicon-16x16.png',
        },
        {
          rel: 'icon',
          type: 'image/x-icon',
          sizes: '32x32',
          href: '/favicon-32x32.png',
        },
        ...i18nSeo.link,
      ],
      // add Google Analytics GA4 as of 22.11.22
      script: [
        {
          hid: 'ga4',
          src: 'https://www.googletagmanager.com/gtag/js?id=G-MD00MG7D4L',
          async: true,
        },
        {
          hid: 'ga4',
          innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-MD00MG7D4L');
          `,
          type: 'text/javascript',
          charset: 'utf-8',
        },
      ],
    }
    // console.log('seo', seo)
    return seo
  },
}
</script>
<style></style>
