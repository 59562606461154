/**
 * Units in DE and FR are stored here together with the corresponding decimals
 */

import _ from 'lodash'

export const state = () => ({
  'de-CH': {
    all: [
      {
        id: 1,
        kennzahl: 'Ergebnis Allgemeiner Haushalt',
        unit: 'CHF/EW',
        decimals: 0,
      },
      {
        id: 2,
        kennzahl:
          'Ergebnis Allgemeiner Haushalt vor zusätzlichen Abschreibungen',
        unit: 'CHF/EW',
        decimals: 0,
      },
      {
        id: 3,
        kennzahl: 'Bilanzüberschuss / Bilanzfehlbetrag in Steueranlagezehntel',
        unit: '',
        decimals: 1,
      },
      {
        id: 4,
        kennzahl: 'Massgebliches Eigenkapital',
        unit: 'CHF/EW',
        decimals: 0,
      },
      {
        id: 5,
        kennzahl: 'Bilanzüberschussquotient',
        unit: '%',
        decimals: 1,
      },
      {
        id: 6,
        kennzahl: 'Investitionsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 7,
        kennzahl: 'Nettoinvestitonen',
        unit: 'CHF/EW',
        decimals: 0,
      },
      {
        id: 8,
        kennzahl: 'Selbstfinanzierungsgrad',
        unit: '%',
        decimals: 1,
      },
      {
        id: 9,
        kennzahl: 'Selbstfinanzierungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 10,
        kennzahl: 'Zinsbelastungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 11,
        kennzahl: 'Nettozinsbelastungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 12,
        kennzahl: 'Bruttoverschuldungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 13,
        kennzahl: 'Nettoverschuldungsquotient',
        unit: '%',
        decimals: 1,
      },
      {
        id: 14,
        kennzahl: 'Nettoschuld',
        unit: 'CHF/EW',
        decimals: 0,
      },
      {
        id: 15,
        kennzahl: 'Kapitaldienstanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 16,
        kennzahl: 'Steueranlage',
        unit: '',
        decimals: 2,
      },

      {
        id: 17,
        kennzahl: 'Einwohner/-innen',
        unit: 'Personen',
        decimals: 0,
      },
      {
        id: 18,
        kennzahl: 'Finanzausgleich',
        unit: '%',
        decimals: 1,
      },
    ],
  },
  'fr-CH': {
    all: [
      {
        id: 1,
        kennzahl: 'Ergebnis Allgemeiner Haushalt',
        unit: 'CHF/hab.',
        decimals: 0,
      },
      {
        id: 2,
        kennzahl:
          'Ergebnis Allgemeiner Haushalt vor zusätzlichen Abschreibungen',
        unit: 'CHF/hab.',
        decimals: 0,
      },
      {
        id: 3,
        kennzahl: 'Bilanzüberschuss / Bilanzfehlbetrag in Steueranlagezehntel',
        unit: '',
        decimals: 1,
      },
      {
        id: 4,
        kennzahl: 'Massgebliches Eigenkapital',
        unit: 'CHF/hab.',
        decimals: 0,
      },
      {
        id: 5,
        kennzahl: 'Bilanzüberschussquotient',
        unit: '%',
        decimals: 1,
      },
      {
        id: 6,
        kennzahl: 'Investitionsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 7,
        kennzahl: 'Nettoinvestitonen',
        unit: 'CHF/hab.',
        decimals: 0,
      },
      {
        id: 8,
        kennzahl: 'Selbstfinanzierungsgrad',
        unit: '%',
        decimals: 1,
      },
      {
        id: 9,
        kennzahl: 'Selbstfinanzierungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 10,
        kennzahl: 'Zinsbelastungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 11,
        kennzahl: 'Nettozinsbelastungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 12,
        kennzahl: 'Bruttoverschuldungsanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 13,
        kennzahl: 'Nettoverschuldungsquotient',
        unit: '%',
        decimals: 1,
      },
      {
        id: 14,
        kennzahl: 'Nettoschuld',
        unit: 'CHF/hab.',
        decimals: 0,
      },
      {
        id: 15,
        kennzahl: 'Kapitaldienstanteil',
        unit: '%',
        decimals: 1,
      },
      {
        id: 16,
        kennzahl: 'Steueranlage',
        unit: '',
        decimals: 2,
      },

      {
        id: 17,
        kennzahl: 'Einwohner/-innen',
        unit: 'personnes',
        decimals: 0,
      },
      {
        id: 18,
        kennzahl: 'Finanzausgleich',
        unit: '%',
        decimals: 1,
      },
    ],
  },
})

export const getters = {
  get: (state) => (locale) => {
    return state[locale].all
  },
  getUnit: (state) => (locale, id) => {
    if (!_.has(state, locale)) {
      return ''
    }
    const obj = state[locale].all.find((d) => d.id === id)
    return obj ? obj.unit : ''
  },
  getDecimals: (state) => (locale, id) => {
    if (!_.has(state, locale)) {
      return 0
    }
    const obj = state[locale].all.find((d) => d.id === id)
    return obj ? obj.decimals : 0
  },
}
