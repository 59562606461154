<template>
  <div>
    <NoticeHeaderComponent />
    <main role="main">
      <nuxt />
      <div class="clearfix invisible">_</div>
    </main>
  </div>
</template>
<script>
import NoticeHeaderComponent from '@/components/layout/NoticeHeaderComponent'
export default {
  components: {
    NoticeHeaderComponent,
  },
}
</script>
