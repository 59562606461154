<template>
  <header>
    <div class="container-xl px-3 px-md-4 px-lg-5">
      <!-- the visibility of this link will force pre-fetch kennzahlen-gemeinden payload -->
      <nuxt-link
        :to="localePath('/kennzahlen/gemeinden')"
        class="pre-fetch-link"
        >.</nuxt-link
      >
      <b-navbar toggleable="lg" type="light" class="px-0 py-be-1 py-lg-0 m-0">
        <b-navbar-brand class="mt-3 mt-lg-4 mb-3 mb-lg-4">
          <NuxtLink :to="localePath('index')">
            <img
              class="logo"
              src="~/assets/img/Kanton-Bern.svg"
              alt="Logo Kanton Bern"
            />
          </NuxtLink>
          <div class="logo-caption">{{ $t('title') }}</div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="flex-column">
          <b-navbar-nav
            class="ml-auto main-menu py-3 py-lg-0 mt-lg-be-3 mb-lg-be-5 order-2"
          >
            <b-nav-item
              :to="localePath('index')"
              exact
              exact-active-class="active"
              >{{ $t('home') }}</b-nav-item
            >
            <NavItemDropdownArrow
              :label="$t('finance')"
              :paths="[localePath('bilanz'), localePath('erfolgsrechnung')]"
            >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('bilanz')"
                >{{ $t('balance') }}</b-dropdown-item
              >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('erfolgsrechnung')"
                >{{ $t('income_statement') }}</b-dropdown-item
              >
            </NavItemDropdownArrow>
            <NavItemDropdownArrow
              :label="$t('key_figures')"
              :paths="[
                localePath('/kennzahlen/gemeinden'),
                localePath('/kennzahlen/verwaltungskreise'),
                localePath('/kennzahlen/kantone'),
              ]"
            >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('/kennzahlen/gemeinden')"
                >{{ $t('communities') }}</b-dropdown-item
              >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('/kennzahlen/verwaltungskreise')"
                >{{ $t('adminstrational_districts') }}</b-dropdown-item
              >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('/kennzahlen/kantone')"
                >{{ $t('cantons') }}</b-dropdown-item
              >
            </NavItemDropdownArrow>
            <NavItemDropdownArrow
              :label="$t('comparison')"
              :paths="[
                localePath('/gemeindevergleich/auswahl'),
                localePath('/gemeindevergleich/alle')
              ]"
            >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('/gemeindevergleich/auswahl')"
                >{{ $t('gemeindevergleich_individuell') }}</b-dropdown-item
              >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :to="localePath('/gemeindevergleich/alle')"
                >{{ $t('gemeindevergleich_explorer') }}</b-dropdown-item
              >
            </NavItemDropdownArrow>
            <!-- <b-nav-item
              :to="localePath('/gemeindevergleich/individuell')"
              exact
              exact-active-class="active"
              >{{ $t('comparison') }}</b-nav-item
            > -->
          </b-navbar-nav>

          <b-navbar-nav
            class="ml-auto service-nav pt-3 pb-3a py-lg-0 mt-lg-be-2 mb-lg-5 order-1"
          >
            <b-nav-item
              class="service-menu"
              :href="agrContactLink"
              target="_blank"
              >{{ $t('contact') }}</b-nav-item
            >
            <div class="language-selector">
              <b-nav-item
                :to="switchLocalePath('de')"
                exact
                exact-active-class="active"
                class="language"
                >DE</b-nav-item
              >
              <span>|</span>
              <b-nav-item
                :to="switchLocalePath('fr')"
                exact
                exact-active-class="active"
                class="language"
                >FR</b-nav-item
              >
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </header>
</template>

<script>
import NavItemDropdownArrow from '@/components/util/NavItemDropdownArrow'

export default {
  components: {
    NavItemDropdownArrow,
  },
  computed: {
    agrContactLink() {
      const locale = this.$i18n.locale
      return `https://www.gemeinden.dij.be.ch/${locale}/start/ueberuns/kontaktformular.html`
      // return `https://www.jgk.be.ch/jgk/${locale}/index/direktion/organisation/agr/kontakt.html`
    },
  },
}
</script>

<style></style>
