import * as d3 from 'd3'

const annotationTooltipID = 'annotation-tooltip'
// eslint-disable-next-line no-unused-vars
function createTooltip(el) {
  const title = d3.select(el).attr('tooltip-text')
  //  console.log('theThis', title)

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('id', annotationTooltipID)
    .attr('role', 'tooltip')
    .attr('tabindex', '-1')
    .classed('tooltip', true)
    .classed('b-tooltip', true)
    .classed('noninteractive', true)
    .classed('bs-tooltip-top', true)
    .attr('x-placement', 'top')
    .style('position', 'absolute')
    // .style(
    //   'transform',
    //   `translate3d(${position.left}px, ${position.top}px, 0px)`
    // )
    .style('top', '0px')
    .style('left', '0px')
    .style('will-change', 'transform')

  tooltip.append('div').classed('tooltip-inner', true).text(title)

  const positions = calcPositions(el, tooltip)
  //  console.log('positions', positions)
  tooltip
    .append('div')
    .classed('arrow', true)
    .style('left', positions.arrow.left + 'px')

  tooltip.style(
    'transform',
    `translate3d(${positions.tooltip.left}px, ${positions.tooltip.top}px, 0px)`
  )
}

function calcPositions(_el, _tooltip) {
  // calculate position of tooltip
  const tooltipBoundingRect = _tooltip.node().getBoundingClientRect()
  // console.log('tooltipBoundingRect', tooltipBoundingRect)

  // const node = d3.select(this).node()

  const elBoundRect = d3.select(_el).node().getBoundingClientRect()
  // console.log('elBoundRect', elBoundRect)

  const elPos = {
    top: elBoundRect.top + window.pageYOffset,
    left: elBoundRect.left + window.pageXOffset,
  }

  // try desired positions
  let left =
    elPos.left + 0.5 * elBoundRect.width - 0.5 * tooltipBoundingRect.width
  const rightTooltipBorder = left + tooltipBoundingRect.width
  // overlap left border
  if (left < 0) {
    left = 0
  }
  // overlap right border
  else if (rightTooltipBorder > window.innerWidth) {
    left = window.innerWidth - tooltipBoundingRect.width
  }

  const tooltipPos = {
    // left: elPos.left - 64 + 0.5 * elBoundRect.width,
    left,
    top: elPos.top - tooltipBoundingRect.height,
  }

  const arrowWidth = 25
  const arrowLeft =
    elPos.left - tooltipPos.left + 0.5 * elBoundRect.width - 0.5 * arrowWidth

  return {
    debug: {
      innerWidth: window.innerWidth,
      rightTooltipBorder,
    },
    tooltip: {
      left: tooltipPos.left,
      top: tooltipPos.top,
    },
    arrow: {
      left: arrowLeft,
      top: 0,
    },
  }
}

function appendAnnotationTooltip() {
  d3.select('body')
    .selectAll('.annotation')
    .on('mouseover', function () {
      createTooltip(this)
    })
    .on('touchstart', function (evt) {
      d3.event.preventDefault()
      createTooltip(this)
    })
    .on('mouseout', function () {
      d3.select('body').select(`#${annotationTooltipID}`).remove()
    })
    .on('touchend', function (evt) {
      d3.event.preventDefault()

      d3.select('body').select('#demo').text('touchend event fired')
      d3.select('body').select(`#${annotationTooltipID}`).remove()
    })
    .on('touchcancel', function (evt) {})

  // make sure tooltip really dissapears when I dont touch screen
  d3.select('body').on('touchend', function () {
    d3.select('body').select(`#${annotationTooltipID}`).remove()
  })
}

export default function (context, inject) {
  inject('appendAnnotationTooltip', appendAnnotationTooltip)
}

//   <div
//   id="__bv_tooltip_54__"
//   role="tooltip"
//   tabindex="-1"
//   class="tooltip b-tooltip noninteractive bs-tooltip-top"
//   x-placement="top"
//   style="
//     position: absolute;
//     transform: translate3d(365px, 719px, 0px);
//     top: 0px;
//     left: 0px;
//     will-change: transform;
//   "
// >
//   <div class="arrow" style="left: 140px"></div>
//   <div class="tooltip-inner">
//     Fashion axe chillwave iPhone chambray quinoa austin. Yr green juice
//     vegan subway tile put a bird on it woke poutine.
//   </div>
// </div>
