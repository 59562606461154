import {
  // interpolateDiscrete,
  interpolate,
  piecewise,
  interpolateRgb,
} from 'd3-interpolate'
import * as d3 from 'd3'
import _ from 'lodash'

const categoryColors = [
  '#393b6c',
  '#8a6f85',
  '#ff7864',
  '#ffa555',
  '#658d94',
  '#3c76b3',
  '#ebd3ae',
  '#964894',
  '#fac873',
  '#644b41',
]

// we need a special order for gemeindevergleich
const categoryColorsGemeindevergleich = [
  '#393b6c',
  '#964894',
  '#ff7864',
  '#ffa555',
  '#658d94',
  '#3c76b3',
  '#fac873',
  '#8a6f85',
  '#644b41',
  '#ebd3ae',
]

const colorsSeqBlue = [
  '#3c76b3',
  '#5b8cbf',
  '#7aa2cb',
  '#9ab8d7',
  '#b9cee4',
  '#d8e4f0',
].reverse() // we want light to dark blues, not dark to light

const colorsDivValGry = [
  '#3c76b3',
  '#779fca',
  '#b1c8e1',
  '#dedede',
  '#ffc9c1',
  '#ffa093',
  '#ff7864',
]

const colorGray = '#dedede'
const colorBlue = '#3c76b3'
const colorRed = '#ff7864'

const colorDeepGry = 'rgb(84, 84, 84)'
// const colorMiddleGry = 'rgb(100, 100, 100)' /// 'rgb(100, 100, 100)'
const colorPaleGry = 'rgb(234, 234, 234)'

const bubbleSelectStrokeColor = colorDeepGry
const mapHighlightStrokeColor = colorDeepGry

function genColors(interpolator, n) {
  const colors = []
  const scale = d3
    .scaleLinear()
    .domain([0, n - 1])
    .range([0, 1])
  for (let i = 0; i < n; i++) {
    const stepVal = scale(i)
    const col = interpolator(stepVal)
    colors.push(col)
  }
  return colors
}

function genSeqBlu(n) {
  // const interpolator = interpolate('#d8e4f0', '#3c76b3')
  const interpolator = interpolate('#d8e4f0', '#3c76b3')

  const colors = genColors(interpolator, n)
  return colors
}

function genSeqZeroBlu(n) {
  const interpolator = interpolate('#d8e4f0', '#3c76b3')
  const blues = genColors(interpolator, n - 1)
  const colors = _.concat([colorGray], blues)
  return colors
}

function genSeqRed(n) {
  const interpolator = interpolate('#ff7864', '#ffc9c1')

  const colors = genColors(interpolator, n)
  return colors
}

function genSeqZeroRed(n) {
  console.log('genSeqZeroRed')
  // const interpolator = interpolate('#ff7864', '#ffc9c1')
  // this is strange, there must be something wrong here
  const reds = genColors(interpolate, n - 1)
  const colors = _.concat(reds, colorGray)
  return colors
}

function genDiv(n) {
  // const interpolator = interpolate("#d8e4f0", "#3c76b3");
  // interpolateRgb.gamma(2.2)
  const interpolator = piecewise(interpolateRgb, [
    colorRed,
    colorGray,
    colorBlue,
  ])

  const colors = genColors(interpolator, n)
  return colors
}

// not nice colors scale for debugging
function genSeqBrn(n) {
  const interpolator = interpolate('cornsilk', 'saddlebrown')

  const colors = genColors(interpolator, n)
  return colors
}

export {
  categoryColors,
  categoryColorsGemeindevergleich,
  colorGray,
  colorBlue,
  colorRed,
  colorsSeqBlue,
  colorsDivValGry,
  colorDeepGry,
  colorPaleGry,
  bubbleSelectStrokeColor,
  mapHighlightStrokeColor,
  genSeqBlu,
  genSeqRed,
  genSeqZeroRed,
  genSeqZeroBlu,
  genDiv,
  genSeqBrn,
}
