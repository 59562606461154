<template>
  <b-nav-item-dropdown left :toggle-class="currentClass">
    <template slot="button-content"
      >{{ label }}
      <SvgArrowDown
        :width="arrowSizePixels"
        :height="arrowSizePixels"
        :class="['dropdown-arrow']"
      />
    </template>
    <slot />
  </b-nav-item-dropdown>
</template>

<script>
import SvgArrowDown from '@/components/util/SvgArrowDown'
import _ from 'lodash'
export default {
  components: {
    SvgArrowDown,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    paths: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      arrowSize: 18,
      activeColor: '#ea161f',
      defaultColor: 'rgba(0, 0, 0, 1)',
    }
  },
  computed: {
    // arrowDirectionClass() {
    //   return "down";
    // },
    getColor() {
      return this.isActive ? this.activeColor : this.defaultColor
    },
    arrowSizePixels() {
      return this.arrowSize + 'px'
    },
    isActive() {
      return _.includes(this.paths, this.$route.path)
    },
    currentClass() {
      return this.isActive ? 'active' : ''
    },
  },
}
</script>

<style scoped>
.dropdown-arrow {
  margin-left: 1px;
}
</style>
