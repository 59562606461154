<template>
  <footer class="footer">
    <div class="container-xl pt-4 pb-4 px-3 px-md-4 px-lg-5">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pb-2 pb-lg-0">
          <div class="footer-nav text-lg-right">
            <nuxt-link :to="localePath('datenquelle')" class="d-inline-block">{{
              $t('data_processing')
            }}</nuxt-link>
            <nuxt-link :to="localePath('datenschutz')" class="d-inline-block">{{
              $t('data_privacy')
            }}</nuxt-link>
            <nuxt-link
              :to="localePath('impressum')"
              class="d-inline-block mr-0"
              >{{ $t('imprint') }}</nuxt-link
            >
          </div>
        </div>
        <div class="col-lg-7 order-lg-1">
          <div>
            <span class="copyright">
              © 2021 – {{ $t('organisation_name') }}
            </span>
          </div>
          <div class="last-update">{{ updateInfo }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import * as d3 from 'd3'
export default {
  fetch() {
    // setting a timestamp at build time to show in footer
    if (process.server) {
      const d = Date.now()
      this.date = this.formatDate(d)
    }
  },
  data() {
    return {
      date: '25.01.2021',
    }
  },
  computed: {
    prefix() {
      return this.$i18n.t('last_update')
    },
    updateInfo() {
      return `${this.prefix}: ${this.date}`
    },
    formatDate() {
      return d3.timeFormat('%d.%m.%Y')
    },
  },
  fetchOnServer: true,
}
</script>

<style></style>
