import { colorDeepGry } from '@/plugins/colors'

const ER_BUBBLE_MAX_RADIUS = 50
const ER_BUBBLE_MIN_RADIUS = 0
const ER_BUBBLE_REF_MAX_VALUE = 4000 // 3565544395 // max 2017: 3565544395
const ER_BUBBLE_STROKE_WIDTH = 1.3
const ER_BUBBLE_COLLISION_OFFSET = 2

const gridBreakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1040,
  xl: 1240,
  xxl: 1340,
}

const containerMaxWidths = {
  sm: 600,
  md: 720,
  lg: 980,
  xl: 1150,
  xxl: 1250,
}
const MAP_STROKE_WIDTH = 0.5
const MAP_HIGHLIGHT_STROKE = colorDeepGry
const MAP_KENNZAHLEN_GEMEINDEN_LEGEND_MAX_DOMAIN = 303

const MINI_LINE_MIN_VALUE = 0
const MINI_LINE_MAX_VALUE = 1300 // 300

// id,kennzahl,min,max
// 6,Investitionsanteil,1.8,34.7
// 8,Selbstfinanzierungsgrad,-20.5,1559.5
// 9,Selbstfinanzierungsanteil,-2.1,31.9
// 10,Zinsbelastungsanteil,-9.6,10
// 11,Bruttoverschuldungsanteil,20.6,219.8
// 13,Nettoschuld je Einwohner,-7318,18711
// 14,Kapitaldienstanteil,-5.4,41.3

const GEMEINDEVERGLEICH_ER_CHART_INNER_WIDTH = 230 // 225

const CHART_ID_PREFIX = 'agr-chart-'

const BUBBLE_HIGHLIGHT_STROKE_WIDTH = 2

export {
  BUBBLE_HIGHLIGHT_STROKE_WIDTH,
  ER_BUBBLE_MAX_RADIUS,
  ER_BUBBLE_MIN_RADIUS,
  ER_BUBBLE_REF_MAX_VALUE,
  ER_BUBBLE_STROKE_WIDTH,
  ER_BUBBLE_COLLISION_OFFSET,
  gridBreakpoints,
  containerMaxWidths,
  MAP_STROKE_WIDTH,
  MAP_HIGHLIGHT_STROKE,
  MAP_KENNZAHLEN_GEMEINDEN_LEGEND_MAX_DOMAIN,
  MINI_LINE_MIN_VALUE,
  MINI_LINE_MAX_VALUE,
  GEMEINDEVERGLEICH_ER_CHART_INNER_WIDTH,
  CHART_ID_PREFIX,
}
